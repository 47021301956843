import { call, put, takeEvery } from "redux-saga/effects"
import toastr from 'toastr'
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  postJwtLogin
} from "../../../helpers/backend_helper"
import { globalLoading } from "react-global-loading"


function* loginUser({ payload: { user, history } }) {
  
  try {
    const response = yield call(postJwtLogin, {
      email: user.email,
      senha: user.senha,
    })
    
    globalLoading.hide();
    localStorage.setItem("token", response.data.token)
    localStorage.setItem("authUser", JSON.stringify(response.data.usuario))
    yield put(loginSuccess(response))
    
    history.push("/dashboard")
  } catch (error) {
    globalLoading.hide();
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("token")
    localStorage.removeItem("authUser")
    yield put(logoutUserSuccess())
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga

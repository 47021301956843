import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {  map } from "lodash"
import { MDBDataTable, MDBTableBody, MDBTableHead, } from "mdbreact"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, CardBody, Card, CardTitle,Progress,Label,Button, Table } from "reactstrap"
import BootstrapTable from 'react-bootstrap-table-next';
import "../../../assets/scss/datatables.scss"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "../../../components/Common/Pagination"
import {getProdutos,deleteFornecedor,getClientesAll,getFornecedoresAll, deleteProduto, exportarProduto} from "../../../helpers/backend_helper"
import { AvField, AvGroup } from "availity-reactstrap-validation"
import Select from 'react-select'
import { toast } from "react-toastify"
import SweetAlert from "react-bootstrap-sweetalert"
import { globalLoading } from "react-global-loading"

let PageSize = 5;

const ListaProdutos = props => {
  const navigate = useHistory();
  const [rowItens, setRowItens] = useState([]);
  const [total, setTotal] = useState(0);
  const [consultaProdutos, setConsultaProdutos] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fornecedores, setFornecedores] = useState([]);
  const [exclusao, setExclusao] = useState({});
  const [consultaFornecedores, setConsultaFornecedores] = useState(true);
  const [clientes, setClientes] = useState([]);
  const [showMsg, setShowMessage] = useState(false)
  const [consultaClientes, setConsultaClientes] = useState(true);

  const currentTableData = useMemo(async () => {
    consultarProdutos();
  }, [currentPage]);

    useEffect(() => {
       if(consultaProdutos) {
          setCurrentPage(1);
          consultarProdutos();
          setConsultaProdutos(false);
        }
    },[consultaProdutos])

    function consultarProdutos() {
      globalLoading.show();
      let nome = document.getElementsByName("nome")[0] && document.getElementsByName("nome")[0].value
      let idCliente = document.getElementsByName("idCliente")[0] && document.getElementsByName("idCliente")[0].value
      let idFornecedor = document.getElementsByName("idFornecedor")[0] && document.getElementsByName("idFornecedor")[0].value
      let descricao = document.getElementsByName("descricao")[0] && document.getElementsByName("descricao")[0].value
      let codInternoCliente = document.getElementsByName("codInternoCliente")[0] && document.getElementsByName("codInternoCliente")[0].value
      let marca = document.getElementsByName("marca")[0] && document.getElementsByName("marca")[0].value
      let codOem = document.getElementsByName("codOem")[0] && document.getElementsByName("codOem")[0].value
      getProdutos(
        {"pagina":currentPage == null || currentPage === 0? 0: currentPage -1  ,
        "tamanho":PageSize, "nome":nome, "idCliente":idCliente, "idFornecedor":idFornecedor,
        "descricao":descricao,"codInternoCliente":codInternoCliente,"marca":marca,"codOem":codOem})
          .then((res) => {
            globalLoading.hide();
            let usuarios = [];
            for (var index = 0; index < res.data.length; index++) {
              let rowItem = {}
              rowItem["idProduto"] = res.data[index].idProduto;
              rowItem["codInternoCliente"] = res.data[index].codInternoCliente;
              rowItem["nome"] = res.data[index].nome;
              rowItem["cliente"] = res.data[index].cliente;
              rowItem["descricao"] =  res.data[index].descricao;
              rowItem["marca"] =  res.data[index].marca;
              usuarios.push(rowItem);
            }
            setTotal(res.total);
            setRowItens(usuarios);
          }).catch(err => {
            globalLoading.hide();
            if(err.response && err.response.status == 401) {
              localStorage.removeItem("token")
              localStorage.removeItem("authUser")
              navigate.push("/login")
            }
          })
    }
    const toCadastroProduto = (produto, isVizualizacao)=> {;
      navigate.push({
        pathname: 'cadastro-produtos',
        state: {produto:produto,vizualizacao:isVizualizacao} 
        });
    }

  useEffect(() => {
    if(consultaClientes) {
      globalLoading.show();
      getClientesAll()
      .then((res) => {
        globalLoading.hide();
        setClientes(res.clientes);
        setConsultaClientes(false)
      }).catch(err => {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          navigate.push("/login")
        }
      });
    }
  },[consultaClientes])

  useEffect(() => {
    if(consultaFornecedores) {
      globalLoading.show();
      getFornecedoresAll()
      .then((res) => {
        globalLoading.hide();
        setFornecedores(res.fornecedores);
        setConsultaFornecedores(false)
      }).catch(err => {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          navigate.push("/login")
        }
      });
    }
  },[consultaFornecedores])

  const excluirProduto = ()=> {
    globalLoading.show();
    deleteProduto(exclusao.idProduto).then(res => {
      globalLoading.hide();
     toast.success('Produto Excluido com sucesso!',{
       position: toast.POSITION.TOP_RIGHT
     })
     setConsultaProdutos(true);
    }).catch(err=> {
      globalLoading.hide();
      if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          navigate.push("/login")
      }
     toast.error(err.response.data.message,{
       position: toast.POSITION.TOP_RIGHT
     })
    })
   }

   const excluirProdutoHandle = (prod)=> {
    setExclusao(prod);
    setShowMessage(true);
   }

   const exportaPdfProduto = () => {
    globalLoading.show();
    let nome = document.getElementsByName("nome")[0] && document.getElementsByName("nome")[0].value
    let idCliente = document.getElementsByName("idCliente")[0] && document.getElementsByName("idCliente")[0].value
    let idFornecedor = document.getElementsByName("idFornecedor")[0] && document.getElementsByName("idFornecedor")[0].value
    let descricao = document.getElementsByName("descricao")[0] && document.getElementsByName("descricao")[0].value
    let codInternoCliente = document.getElementsByName("codInternoCliente")[0] && document.getElementsByName("codInternoCliente")[0].value
    let marca = document.getElementsByName("marca")[0] && document.getElementsByName("marca")[0].value
    let codOem = document.getElementsByName("codOem")[0] && document.getElementsByName("codOem")[0].value
    exportarProduto(
      {"pagina":currentPage == null || currentPage === 0? 0: currentPage -1  ,
      "tamanho":PageSize, "nome":nome, "idCliente":idCliente, "idFornecedor":idFornecedor,
      "descricao":descricao,"codInternoCliente":codInternoCliente,"marca":marca,"codOem":codOem}).then(res => {
      globalLoading.hide();
      window.open(res.link, '_blank', 'noopener,noreferrer');
     }).catch(err=> {
      globalLoading.hide();
      if(err.response && err.response.status == 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("authUser")
        navigate.push("/login")
      }
      toast.error(err.response.data.message,{
        position: toast.POSITION.TOP_RIGHT
      })
     })
   }

  return (
    <React.Fragment>
       <div className="page-content">
       
       <Breadcrumbs title="Tables" breadcrumbItem="Consulta de Produtos" />
        <Row>
          <Card>
            <CardBody>
            <Row>
            <Col lg={4}>
              <Label className="form-label">Nome</Label>
                    <input
                      className="form-control"
                      type="text"
                      name="nome"
                      placeholder="Nome"
                    />
              </Col>
              <Col lg={4}>
              <Label className="form-label">Cliente</Label>
                <select  className="form-control" name="idCliente" label="Cliente">
                    <option></option>
                    {clientes.map(cliente => <option value={cliente.idCliente}>{cliente.nome}</option>)}
                </select>
              </Col>
              <Col lg={4}>
              <Label className="form-label">Fornecedor</Label>
                <select  className="form-control" name="idFornecedor" label="Cliente">
                    <option></option>
                    {fornecedores.map(fornecedor => <option value={fornecedor.idFornecedor}>{fornecedor.nome}</option>)}
                </select>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col lg={3}>
                <Label className="form-label">Descrição</Label>
                      <input
                        className="form-control"
                        type="text"
                        name="descricao"
                        placeholder="Descrição"
                      />
              </Col>
              <Col lg={3}>
              <Label className="form-label">Código Interno do Cliente</Label>
                      <input
                        className="form-control"
                        type="text"
                        name="codInternoCliente"
                        placeholder="Código Interno do Cliente"
                      />
              </Col>
              <Col lg={3}>
              <Label className="form-label">Marca</Label>
                      <input
                        className="form-control"
                        type="text"
                        name="marca"
                        placeholder="Marca"
                      />
              </Col>
              <Col lg={3}>
              <Label className="form-label">Código OEM</Label>
                      <input
                        className="form-control"
                        type="text"
                        name="codOem"
                        placeholder="Código OEM"
                      />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col lg={9}></Col>
              <Col lg={3} className="d-flex flex-row-reverse">
                <Link to="cadastro-produtos" className="btn btn-primary waves-effect waves-light">
                  Novo <i className="bx bx-plus font-size-8 align-middle me-1"></i>
                </Link> 
                &nbsp; &nbsp; &nbsp;
                <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light" onClick={() => exportaPdfProduto()}>
                      Exportar <i className="bx bx-save font-size-8 align-middle me-1"></i>
                  </Button>
                &nbsp; &nbsp; &nbsp;
                <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light" onClick={() => setConsultaProdutos(true)}>
                      Consultar <i className="bx bx-search font-size-8 align-middle me-1"></i>
                  </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Lista de Produtos</CardTitle>
                    <table  className="table table-hover mb-0">
                      <thead className="table-light">
                        <tr>
                          <th style={{width:  '10%', textAlign:'center'}}>Código Cliente</th>
                          <th style={{width:  '11%'}}>Nome</th>
                          <th style={{width:  '15%'}}>Cliente</th>
                          <th style={{width:  '46%'}}>Descrição</th>
                          <th style={{width:  '6%'}}>Marca</th>
                          <th style={{width:  '12%', textAlign:'center'}}>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        { rowItens.map(row => 
                            <tr key={row.codInternoCliente}>
                              <td style={{width:  '10%', textAlign:'center'}}>{row.codInternoCliente}</td>
                              <td style={{width: '11%'}}>{row.nome}</td>
                              <td style={{width: '15%'}}>{row.cliente.nome}</td>
                              <td style={{width: '46%'}}>{row.descricao}</td>
                              <td style={{width: '6%'}}>{row.marca}</td>
                              <td style={{width: '12%', textAlign:'center'}}>
                              <button type="button" className="btn btn-light waves-effect waves-light" 
                                 onClick={() => toCadastroProduto(row,false) }>
                                  <i className="bx bx-edit font-size-8 align-middle me-1"></i>
                                </button>
                                &nbsp;
                                <button type="button" className="btn btn-light waves-effect waves-light"
                                onClick={() => toCadastroProduto(row,true) }>
                                  <i className="bx bx-search font-size-8 align-middle me-1"></i>
                                </button>
                                &nbsp;
                                <button type="button" className="btn btn-light waves-effect waves-light"
                                onClick={() => excluirProdutoHandle(row) }>
                                  <i className="bx bx-trash font-size-8 align-middle me-1"></i>
                                </button>
                              </td>
                              {showMsg ? (<SweetAlert
                                  title="Deseja Realmente Excluir este Produto?"
                                  warning
                                  showCancel
                                  confirmBtnBsStyle="Sim"
                                  cancelBtnBsStyle="Não"
                                  onConfirm={() => {
                                    excluirProduto()
                                    setShowMessage(false);
                                  }}
                                  onCancel={() => {
                                    setShowMessage(false);
                                  }}>
                                </SweetAlert>):null}
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                    <Row>
                      <Col lg={5} ></Col>
                      <Col lg={1} >
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={total}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                          />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
              
            </CardBody>
          </Card>
        </Row>
       </div>
    </React.Fragment>
  )
}

ListaProdutos.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
}


export default ListaProdutos

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, CardBody, Card, Button, Form, FormFeedback, FormGroup, Input, Label, } from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import { AvForm, AvField, AvGroup, AvInput,AvFeedback } from 'availity-reactstrap-validation';
import { createOrcamento, editOrcamento, getClientesAll, getOrcamento, getProdutosAll } from "../../../helpers/backend_helper";
import {toast } from 'react-toastify';
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min'; 
import { globalLoading } from "react-global-loading";
import AutoComplete from "../../../components/Common/AutoComplete";
import '../../../components/Common/AutoComplete.scss';
import {headersComAcao} from "../../../common/headers-produto-orcamento-com-acao.json"
import {headersSemAcao} from "../../../common/headers-produto-orcamento-sem-acao.json"
const CadastroOrcamentos = props => {
  const history = useHistory();

  const [salvarOrcamento, setSalvarOrcamento] = useState(false);
  const [orcamento, setOrcamento] = useState({});
  const [clientes, setClientes] = useState([]);
  const [consultaClientes, setConsultaClientes] = useState(true);
  const [produtos, setProdutos] = useState([]);
  const [cliente, setCliente] = useState(null);
  const [produto, setProduto] = useState({});
  const [precoTotal, setPrecoTotal] = useState(0,0);
  const [pesoTotalBruto, setPesoTotalBruto] = useState(0,0);
  const [produtoExcluido, setProdutoExcluido] = useState({});
  const [excluirProd, setExcluirProd] = useState(false);  
  const [active, setActive] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [input, setInput] = useState('');
  const [isClienteSelecionado, setIsClienteSelecionado] = useState(false);
  const [form, setForm] = useState({});

  const [produtosOrcamento, setProdutosOrcamento] = useState({
   "columns":headersComAcao ,
    "rows":[]
  });
  const [idOrcamento, setIdOrcamento] = useState(null);
  const [vizualizacao, setVizualizacao] = useState(false);

  useEffect(() => {
    if(salvarOrcamento) {
      globalLoading.show();
      let lstProdutos = montarListaProdutos();
      orcamento.listaProdutos = lstProdutos;
      if(!orcamento.idOrcamento) {
        createOrcamento(orcamento).then(res => {
          globalLoading.hide();
          setOrcamento({});
            toast.success("Orçamento Salvo com Sucesso",{
              position: toast.POSITION.TOP_RIGHT
            }) 
            history.push('lista-orcamentos')
        }).catch(err => {
          globalLoading.hide();
          if(err.response && err.response.status == 401) {
            localStorage.removeItem("token")
            localStorage.removeItem("authUser")
            history.push("/login")
          }
          toast.error(err.message,{
            position: toast.POSITION.TOP_RIGHT
          })
          produto.cliente = cliente;
          setSalvarOrcamento(false);
        });
    } else {
      let orcamentoEdit = {...orcamento}
      orcamentoEdit.idCliente = typeof cliente === 'object' ? cliente.idCliente:cliente;
      delete orcamentoEdit['cliente'];
      editOrcamento(orcamentoEdit).then(res => {
        globalLoading.hide();
        setOrcamento({});
        toast.success("Orçamento editado com Sucesso",{
          position: toast.POSITION.TOP_RIGHT
        }) 
        history.push('lista-orcamentos')
      }).catch(err => {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          history.push("/login")
        }
        toast.error(err.message,{
          position: toast.POSITION.TOP_RIGHT
        });
        produto.cliente = cliente;
        setSalvarOrcamento(false);
      })
    }
    setSalvarOrcamento(false);
  }
 },[salvarOrcamento]);

 function montarListaProdutos() {
    let lstProdutos = [];
    if(produtosOrcamento.rows.length > 0) {
      for (var index = 0; index < produtosOrcamento.rows.length; index++) {
        let rowItem = {}
        rowItem["idProduto"] = produtosOrcamento.rows[index].produto.idProduto;
        rowItem["quantidade"] = produtosOrcamento.rows[index].quantidade;
        lstProdutos.push(rowItem);
      }
    }
    return lstProdutos;
 }

  const handleSubmit = (event, values) => {
    if(produtosOrcamento.rows.length == 0) {
      toast.error('É necessario incluir ao menos um produto para salvar o orçamento',{
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if(!orcamento.idOrcamento) {
      values.precoTotal = precoTotal;
      values.pesoTotalBruto = pesoTotalBruto;
      setOrcamento(values)
      
    } else {
      orcamento.precoTotal = precoTotal;
      orcamento.pesoTotalBruto = pesoTotalBruto;
      orcamento.observacao = values.observacao; 
    }
    setSalvarOrcamento(true);
  };

  useEffect(() => {
    if(!idOrcamento && props.location && props.location.state && !produto.idOrcamento) {
      setIdOrcamento(props.location.state.orcamento.idOrcamento || {});
      setVizualizacao(props.location.state.vizualizacao);
    }
  })

  useEffect(() => {
    if(typeof idOrcamento == 'number' && !orcamento.idOrcamento) {
      globalLoading.show();
      getOrcamento(idOrcamento)
        .then((res) => {
          globalLoading.hide();
          setOrcamento(res.data);
          setCliente(res.data.cliente.idCliente);
          setIsClienteSelecionado(true);
          let produtos = [];
          let pesoBrutoCalculado = 0;
          let precoTotalCalculado = 0;
          for (var index = 0; index < res.data.listaProdutos.length; index++) {
            let descricao = res.data.listaProdutos[index].produto.descricao;
            let codInterno = res.data.listaProdutos[index].produto.codInternoCliente;
            let codOem = res.data.listaProdutos[index].produto.codOem;
            let rowItem = {
              produto: {
                idProduto: res.data.listaProdutos[index].produto.idProduto,
                nome:res.data.listaProdutos[index].produto.nome,
                quantidade:res.data.listaProdutos[index].quantidade,
                marca:res.data.listaProdutos[index].produto.marca
              }, 
              "produto.nome": montarNomeProduto(codInterno,descricao,codOem),
              quantidade:res.data.listaProdutos[index].quantidade,
              marca:res.data.listaProdutos[index].produto.marca,
              acao:  <>{!vizualizacao && (<button type="button" className="btn btn-light waves-effect waves-light"
                        onClick={() => setProdutoExcluido(rowItem) }>
                        <i className="bx bx-trash font-size-8 align-middle me-1"></i>
                      </button>)}</>
              
            }
            let preco = res.data.listaProdutos[index].produto.precoUnit;
            let pesoBruto = res.data.listaProdutos[index].produto.pesoBrutoUnit;
            let quantidade = res.data.listaProdutos[index].quantidade;
            pesoBrutoCalculado = parseFloat(pesoBrutoCalculado)+(pesoBruto* parseInt(quantidade));
            precoTotalCalculado = precoTotalCalculado+(preco* parseInt(quantidade));
            
            produtos.push(rowItem);
          }
          setPesoTotalBruto(parseFloat(pesoBrutoCalculado).toFixed(2));
          setPrecoTotal(precoTotalCalculado);
          let data = {
            "columns":!vizualizacao ? headersComAcao:headersSemAcao,
            "rows":produtos
          }
          setProdutosOrcamento(data);
    }).catch(err => {
      globalLoading.hide();
      if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          history.push("/login")
      }
    });
  }
  },[idOrcamento]);

  const montarNomeProduto = (codInterno, descricao, codOem) => {
    return codInterno + ' - ' + descricao + ' - ' + (codOem == null?'':codOem);
  }

  const handleSubmitProdutos = (event, values) => {
    if(produto.idProduto) {
      if(!produtosOrcamento.rows.find(prodOr => parseInt(prodOr.produto.idProduto) === parseInt(produto.idProduto))) {
        let rowItem = {
          produto: produto,
          "produto.nome": produto.nome,
          quantidade:values.quantidade,
          marca:produto.marca,
          acao: <button type="button" className="btn btn-light waves-effect waves-light"
                    onClick={() => setProdutoExcluido({quantidade:values.quantidade,produto:produto}) }>
                    <i className="bx bx-trash font-size-8 align-middle me-1"></i>
                  </button>
        }
        let preco = produto.precoUnit == null? 0 :produto.precoUnit;
        let pesoBruto = produto.pesoBrutoUnit;
        let pesoBrutoCalculado = parseFloat(pesoTotalBruto)+(pesoBruto* parseInt(values.quantidade));
        setPrecoTotal(precoTotal+(preco* parseInt(values.quantidade)));
        setPesoTotalBruto(parseFloat(pesoBrutoCalculado).toFixed(2));
        produtosOrcamento.rows.push(rowItem);
        setProdutosOrcamento({
          "columns":produtosOrcamento.columns,
          "rows":produtosOrcamento.rows
        });
        form.reset();
      } else {
        toast.error('Produto ja Cadastrado na Lista.',{
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } else {
      toast.error('Por favor Selecionar o produto na lista do AutoComplete.',{
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  useEffect(() => {
    if(consultaClientes) {
      globalLoading.show();
      getClientesAll()
      .then((res) => {
        globalLoading.hide();
        setClientes(res.clientes);
        setConsultaClientes(false)
      }).catch(err => {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          history.push("/login")
        }
      });
    }
  },[consultaClientes])

  useEffect(() => {
    if(cliente) {
      globalLoading.show();
      getProdutosAll(cliente)
      .then((res) => {
        globalLoading.hide();
        setProdutos(res.produtos);
      }).catch(err => {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          history.push("/login")
        }
      });
    }
  },[cliente])

  const handleChangeCliente = (event) => {
    setCliente(event.target.value);
    setIsClienteSelecionado(true);
    let data = {
      "columns":headersComAcao,
      "rows":[]
    }
    setProdutosOrcamento(data);
  };

  const filterItemFromArray =  (idx, array) => array.filter(item => item.produto.idProduto !== idx)
  useEffect(() => {
    if(produtoExcluido.produto) {
      let produto = produtos.find(prod => prod.idProduto === parseInt(produtoExcluido.produto.idProduto));
      const remainingItems = filterItemFromArray(produtoExcluido.produto.idProduto, produtosOrcamento.rows);
      produtosOrcamento.rows = remainingItems; 
      if(produto) {
        let preco = produto.precoUnit == null? 0: produto.precoUnit;
        let pesoBruto = produto.pesoBrutoUnit;
        
        let pesoBrutoCalculado = parseFloat(pesoTotalBruto)-(pesoBruto* parseInt(produtoExcluido.quantidade));
        setPesoTotalBruto(parseFloat(pesoBrutoCalculado).toFixed(2));
        setPrecoTotal(precoTotal-(parseFloat(preco)* parseInt(produtoExcluido.quantidade)));
      }
      setProdutosOrcamento({
        columns: produtosOrcamento.columns,
        rows:produtosOrcamento.rows
      })
    }
  },[produtoExcluido]);

  const onChange = e => {
    const input = e.currentTarget.value;
    const newFilteredItems = produtos.filter(
      item =>
        item.nome.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
    setActive(0);
    setFiltered(newFilteredItems);
    setIsShow(true);
    setInput(e.currentTarget.value);
    setProduto({})
  };
const onClick = produto => {
    setActive(0);
    setFiltered([]);
    setIsShow(false);
    setInput(produto.nome);
    setProduto(produto);
  };
  
const onKeyDown = e => {
    if (e.keyCode === 13) { // enter key
      setActive(0);
      setIsShow(false);
      setInput(filtered[active])
    }
    else if (e.keyCode === 38) { // up arrow
      return (active === 0) ? null : setActive(active - 1);
    }
    else if (e.keyCode === 40) { // down arrow
      return (active - 1 === filtered.length) ? null : setActive(active + 1);
    }
  };
const renderAutocomplete = () => {
    if (isShow && input) {
      if (filtered.length) {
        return (
          <ul className="autocomplete">
            {filtered.map((item, index) => {
              let className;
              if (index === active) {
                className = "active";
              }
              return (
                <li className={className} key={item.idProduto} onClick={()=>onClick(item)}>
                  {item.nome}
                </li>
              );
            })}
          </ul>
        );
      } else {
        return (
          <div className="no-autocomplete">
            <em>Nenhum produto encontrado</em>
          </div>
        );
      }
    }
    return null;
  }

  return (
    <React.Fragment>
    <div className="page-content">
        <Row>
       <Card>
         <CardBody>
         <AvForm onValidSubmit={handleSubmit}>
         <Row>
            <Col lg={9}>
                <h4 className="page-title mb-0 font-size-18">Cadastro de Orçamentos</h4>
            </Col>
            <Col lg={3} className="d-flex flex-row-reverse">
                {!vizualizacao && (<Button
                        color="primary" type="submit"
                        className="btn btn-primary waves-effect waves-light">
                        Salvar Orçamento <i className="bx bx-check font-size-8 align-middle me-1"></i>
                  </Button>
                  )}
                  &nbsp;
                  <Link to="lista-Orcamentos" className="btn btn-primary waves-effect waves-light">
                    voltar <i className="bx bx-arrow-back font-size-8 align-middle me-1"></i>
                  </Link>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <AvGroup>
                <AvField type="select" name="idCliente" label="Cliente"
                value={orcamento.cliente? orcamento.cliente.idCliente:null}
                disabled ={vizualizacao || idOrcamento} onChange={handleChangeCliente}
                validate={{required: { value: true, errorMessage: 'Selecione o Cliente'}}}>
                    <option></option>
                    {clientes.map(cliente => <option value={cliente.idCliente}>{cliente.nome}</option>)}
                </AvField>
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
          <Col md={12}>
            <AvGroup>
            <Label className="form-label" htmlFor="precoTotal">Observações</Label>
                <AvInput
                      name="observacao"
                      type="textarea"
                      rows="5"
                      placeholder="Observações"
                      id="observacao"
                      value= {orcamento.observacao ? orcamento.observacao : null}
                      disabled ={vizualizacao}
                    />
               <AvFeedback>Informe o Preço Total</AvFeedback>
              </AvGroup>
            </Col>
          </Row>
          
          </AvForm>
          {!vizualizacao && (<AvForm onValidSubmit={handleSubmitProdutos} id="formProdutos"  ref={c => (setForm(c))}>
          <Breadcrumbs title="Tables" breadcrumbItem="Lista de Produtos" />
          <Row>
          <Col md={4}>
            <AvGroup>
            <Label className="form-label">Produto</Label>
            <AvInput
              type="text"
              name ="idProduto"
              onChange={onChange}
              onKeyDown={onKeyDown}
              value={input}
              disabled={!isClienteSelecionado}
              required
            />
            <AvFeedback>Selecione o Produto</AvFeedback>
            {renderAutocomplete()}
            </AvGroup>
            </Col>
            <Col md={4}>
              <AvGroup>
                <Label className="form-label" htmlFor="quantidade">Quantidade</Label>
                  <AvInput
                        name="quantidade"
                        required
                        type="number"
                        placeholder="Quantidade"
                        id="quantidade"
                      />
                <AvFeedback>Informe a Quantidade de Produtos</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={2}>
                <Button
                  color="primary" type="submit" style={{marginTop:"12%"}}
                  className="btn btn-primary waves-effect waves-light">
                  Incluir Produto <i className="bx bx-check font-size-8 align-middle me-1"></i>
                </Button>
            </Col>
          </Row>
          </AvForm>)}
          <br></br>
          <br></br>
          <MDBDataTable hover data={produtosOrcamento} 
          noBottomColumns={true} order={false} noRecordsFoundLabel="Não foi encontrado nenhum produto!"
          displayEntries={false}> </MDBDataTable>
          <Row>
          <Col md={6}>
            <h4 >Preço Total: R$ {precoTotal == '0'?'0':precoTotal}</h4>
            </Col>
            <Col md={6}>
            <h4 >Peso Total Bruto: {pesoTotalBruto == '0'?'0':pesoTotalBruto} KG</h4>
            </Col>
          </Row>
         </CardBody>
       </Card>
     </Row>
    </div>
 </React.Fragment>
  )
}

export default CadastroOrcamentos

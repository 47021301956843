import { GET_USUARIOS_SUCCESS, GET_USUARIOS_FAIL, GET_USUARIO_SUCCESS, GET_USUARIO_FAIL, CREATE_USUARIOS_SUCCESS, CREATE_USUARIOS_FAIL, EDIT_USUARIOS_SUCCESS, EDIT_USUARIOS_FAIL, DELETE_USUARIOS_SUCCESS, DELETE_USUARIOS_FAIL } from "./actionTypes"

const INIT_STATE = {
  usuarios: [],
  usuario: {},
  error: {},
}

const usuario = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USUARIOS_SUCCESS:
      return {
        ...state,
        usuarios: action.payload,
      }

    case GET_USUARIOS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_USUARIO_SUCCESS:
        return {
          ...state,
          usuario: action.payload,
        }
  
    case GET_USUARIO_FAIL:
        return {
          ...state,
          error: action.payload,
        }
    case CREATE_USUARIOS_SUCCESS:
        return {
          ...state,
          usuario: action.payload,
        }
    
    case CREATE_USUARIOS_FAIL:
        return {
          ...state,
          error: action.payload,
        }  
    case EDIT_USUARIOS_SUCCESS:
        return {
            ...state,
            usuario: action.payload,
          }
      
    case EDIT_USUARIOS_FAIL:
        return {
          ...state,
          error: action.payload,
        }
    case DELETE_USUARIOS_SUCCESS:
        return {
            ...state,
           usuario: action.payload,
          }
        
    case DELETE_USUARIOS_FAIL:
        return {
          ...state,
          error: action.payload,
        }  

    default:
      return state
  }
}

export default usuario

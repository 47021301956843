import { CREATE_USUARIOS, CREATE_USUARIOS_FAIL, CREATE_USUARIOS_SUCCESS, DELETE_USUARIOS, DELETE_USUARIOS_FAIL, DELETE_USUARIOS_SUCCESS, EDIT_USUARIOS, EDIT_USUARIOS_FAIL, EDIT_USUARIOS_SUCCESS, GET_USUARIO, GET_USUARIOS, GET_USUARIOS_FAIL, GET_USUARIOS_SUCCESS, GET_USUARIO_FAIL, GET_USUARIO_SUCCESS } from "./actionTypes"

export const getUsuarios = paginacao => ({
  type: GET_USUARIOS,
  paginacao
})

export const getUsuariosSucess = usuarios => ({
  type: GET_USUARIOS_SUCCESS,
  payload: usuarios,
})

export const getUsuariosErro = error => ({
  type: GET_USUARIOS_FAIL,
  payload: error,
})

export const getUsuario = idUsuario => ({
  type: GET_USUARIO,
  idUsuario
})

export const getUsuarioSucess = usuario => ({
  type: GET_USUARIO_SUCCESS,
  payload: usuario,
})

export const getUsuarioErro = error => ({
  type: GET_USUARIO_FAIL,
  payload: error,
})

export const createUsuario = () => ({
  type: CREATE_USUARIOS
})

export const createUsuarioSuccess = response => ({
  type: CREATE_USUARIOS_SUCCESS,
  payload: response,
})

export const createUsuarioErro = error => ({
  type: CREATE_USUARIOS_FAIL,
  payload: error,
})

export const editUsuario = () => ({
  type: EDIT_USUARIOS
})

export const editUsuarioSuccess = response => ({
  type: EDIT_USUARIOS_SUCCESS,
  payload: response,
})

export const editUsuarioErro = error => ({
  type: EDIT_USUARIOS_FAIL,
  payload: error,
})

export const deleteUsuario = idUsuario => ({
  type: DELETE_USUARIOS,
  idUsuario
})

export const deleteUsuarioSuccess = response => ({
  type: DELETE_USUARIOS_SUCCESS,
  payload: response,
})

export const deleteUsuarioErro = error => ({
  type: DELETE_USUARIOS_FAIL,
  payload: error,
})


import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, CardBody, Card, CardTitle,Progress,Label,Button, Table } from "reactstrap"
import "../../../assets/scss/datatables.scss"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "../../../components/Common/Pagination"
import {getFornecedores,deleteFornecedor, getExportadoresAll} from "../../../helpers/backend_helper"
import { toast } from "react-toastify"
import SweetAlert from "react-bootstrap-sweetalert"
import { globalLoading } from "react-global-loading"
let PageSize = 5;

const ListaFornecedores = props => {
  const history = useHistory();
  const navigate = useHistory();
  const [rowItens, setRowItens] = useState([]);
  const [exclusao, setExclusao] = useState({});
  const [exportador, setExportador] = useState({});
  const [total, setTotal] = useState(0);
  const [consultaFornecedores, setConsultaFornecedores] = useState(true);
  const [currentPage, setCurrentPage] = useState(null);
  const [showMsg, setShowMessage] = useState(false);
  const [exportadores, setExportadores] = useState([]);
  const [consultaExportadores, setConsultaExpotadores] = useState(true);

  const currentTableData = useMemo(async () => {
    consultarExportadores();
  }, [currentPage]);

    useEffect(() => {
       if(consultaFornecedores) {
        setCurrentPage(1);
        consultarExportadores();
        setConsultaFornecedores(false);
        }
    },[consultaFornecedores])

    useEffect(() => {
      if(consultaExportadores) {
        globalLoading.show();
        getExportadoresAll()
        .then((res) => {
          globalLoading.hide();
          setExportadores(res.exportadores);
          setConsultaExpotadores(false)
        }).catch(err=> {
          globalLoading.hide();
          if(err.response && err.response.status == 401) {
            localStorage.removeItem("token")
            localStorage.removeItem("authUser")
            history.push("/login")
          }
         toast.error(err.response.data.message,{
           position: toast.POSITION.TOP_RIGHT
         })
        });
      }
    },[consultarExportadores])

    function consultarExportadores() {
      globalLoading.show();
      let nome = document.getElementsByName("nome")[0] && document.getElementsByName("nome")[0].value
      let idExportador = exportador.idExportador;
      getFornecedores(
        {"pagina":currentPage == null || currentPage === 0? 0: currentPage -1  ,
        "tamanho":PageSize, "nome":nome, "idExportador":idExportador})
          .then((res) => {
            globalLoading.hide();
            let usuarios = [];
            for (var index = 0; index < res.data.length; index++) {
              let rowItem = {}
              rowItem["idFornecedor"] = res.data[index].idFornecedor
              rowItem["nome"] = res.data[index].nome
              rowItem["descricao"] =  res.data[index].descricao
              usuarios.push(rowItem);
            }
            setTotal(res.total);
            setRowItens(usuarios);
          }).catch(err => {
            globalLoading.hide();
            if(err.response && err.response.status == 401) {
              localStorage.removeItem("token")
              localStorage.removeItem("authUser")
              navigate.push("/login")
            }
          })
    }
    const toCadastroExportador = (fornecedor, isVizualizacao)=> {;
      navigate.push({
        pathname: 'cadastro-fornecedores',
        state: {fornecedor:fornecedor,vizualizacao:isVizualizacao} 
        });
    }

    const excluirFornecedor = ()=> {
    globalLoading.show();
      deleteFornecedor(exclusao.idFornecedor).then(res => {
        globalLoading.hide();
       toast.success('Fornecedor Excluido com sucesso!',{
         position: toast.POSITION.TOP_RIGHT
       })
       setConsultaFornecedores(true);
      }).catch(err=> {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          navigate.push("/login")
        }
       toast.error(err.response.data.message,{
         position: toast.POSITION.TOP_RIGHT
       })
      })
     }

     const excluirFornecedorHandle = (forn)=> {
      setExclusao(forn);
      setShowMessage(true);
     }

     const handleChangeExport = (event) => {
      setExportador(event.target.value);
    };

  return (


    <React.Fragment>
       <div className="page-content">
       <Breadcrumbs title="Tables" breadcrumbItem="Consulta de Fornecedores" />
        <Row>
          <Card>
            <CardBody>
            <Row>
            <Col lg={6}>
              <Label className="form-label">Nome</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Nome"
                      name="nome"
                    />
              </Col>
              
            <Col md={6}>
              <Label className="form-label">Exportador</Label>
              <select type="select" className="form-control" name="idExportador" label="Exportador" onChange={handleChangeExport}>
                  <option value={null}></option>
                  {exportadores.map(exportador => <option value={exportador.idExportador}>{exportador.nome}</option>)}
              </select>
            </Col>
            </Row>
            <br></br>
            <Row className="">
              <Col lg={9}></Col>
              <Col lg={3} className="d-flex flex-row-reverse">
                <Link to="cadastro-Fornecedores" className="btn btn-primary waves-effect waves-light">
                  Novo <i className="bx bx-plus font-size-8 align-middle me-1"></i>
                </Link>
                &nbsp; &nbsp; &nbsp;
                <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light" onClick={() => setConsultaFornecedores(true)}>
                      Consultar <i className="bx bx-search font-size-8 align-middle me-1"></i>
                  </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Lista de Fornecedores</CardTitle>
                    <table  className="table table-hover mb-0">
                      <thead className="table-light">
                        <tr>
                          <th style={{width:  '70%'}}>Nome</th>
                          <th style={{width:  '18%'}}>Descrição</th>
                          <th style={{width:  '12%', textAlign:'center'}}>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        { rowItens.map(row => 
                            <tr key={row.idFornecedor}>
                              <td style={{width: '70%'}}>{row.nome}</td>
                              <td style={{width: '18%'}}>{row.descricao}</td>
                              <td style={{width: '12%', textAlign:'center'}}>
                                <button type="button" className="btn btn-light waves-effect waves-light" 
                                 onClick={() => toCadastroExportador(row,false) }>
                                  <i className="bx bx-edit font-size-8 align-middle me-1"></i>
                                </button>
                                &nbsp;
                                <button type="button" className="btn btn-light waves-effect waves-light"
                                onClick={() => toCadastroExportador(row,true) }>
                                  <i className="bx bx-search font-size-8 align-middle me-1"></i>
                                </button>
                                &nbsp;
                                <button type="button" className="btn btn-light waves-effect waves-light"
                                onClick={() => excluirFornecedorHandle(row) }>
                                  <i className="bx bx-trash font-size-8 align-middle me-1"></i>
                                </button>
                              </td>
                              {showMsg ? (<SweetAlert
                                  title="Deseja Realmente Excluir este Fornecedor?"
                                  warning
                                  showCancel
                                  confirmBtnBsStyle="Sim"
                                  cancelBtnBsStyle="Não"
                                  onConfirm={() => {
                                    excluirFornecedor()
                                    setShowMessage(false);
                                  }}
                                  onCancel={() => {
                                    setShowMessage(false);
                                  }}>
                                </SweetAlert>):null}
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                    <Row style={{display:'flex'}}>
                      <Col lg={5} ></Col>
                      <Col lg={1} >
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={total}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                          />
                      </Col>
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
            </Row>
              
            </CardBody>
          </Card>
        </Row>
       </div>
    </React.Fragment>
  )
}

ListaFornecedores.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
}


export default ListaFornecedores

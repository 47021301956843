import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, CardBody, Card, CardTitle,Progress,Label,Button, Table } from "reactstrap"
import "../../../assets/scss/datatables.scss"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "../../../components/Common/Pagination"
import {getUsuarios,deleteFornecedor, deleteUsuario} from "../../../helpers/backend_helper"
import { toast } from "react-toastify"
import SweetAlert from "react-bootstrap-sweetalert"
import { globalLoading } from "react-global-loading"
let PageSize = 5;

const ListaUsuarios = props => {
  const navigate = useHistory();
  const [rowItens, setRowItens] = useState([]);
  const [total, setTotal] = useState(0);
  const [consultaUsuarios, setConsultaUsuarios] = useState(true);
  const [showMsg, setShowMessage] = useState(false);
  const [exclusao, setExclusao] = useState({});
  const [currentPage, setCurrentPage] = useState(null);

  const currentTableData = useMemo(async () => {
    consultarUsuarios();
  }, [currentPage]);

    useEffect(() => {
       if(consultaUsuarios) {
        setCurrentPage(1);
        consultarUsuarios();
        setConsultaUsuarios(false);
        }
    },[consultaUsuarios])

    function consultarUsuarios() {
      globalLoading.show();
      let email = document.getElementsByName("email")[0] && document.getElementsByName("email")[0].value
      getUsuarios(
        {"pagina":currentPage == null || currentPage === 0? 0: currentPage -1  ,
        "tamanho":PageSize, "email":email})
          .then((res) => {
            globalLoading.hide();
            let usuarios = [];
            for (var index = 0; index < res.data.length; index++) {
              let rowItem = {}
              rowItem["idUsuario"] = res.data[index].idUsuario
              rowItem["email"] = res.data[index].email
              rowItem["status"] =  res.data[index].status
              usuarios.push(rowItem);
            }
            setTotal(res.total);
            setRowItens(usuarios);
          }).catch(err => {
            globalLoading.hide();
            if(err.response && err.response.status == 401) {
              localStorage.removeItem("token")
              localStorage.removeItem("authUser")
              navigate.push("/login")
            }
          })
    }
    const toCadastroUsuario = (usuario, isVizualizacao)=> {;
      navigate.push({
        pathname: 'cadastro-usuarios',
        state: {usuario:usuario,vizualizacao:isVizualizacao} 
        });
    }

    const excluirUsuario = ()=> {
      globalLoading.show();
      deleteUsuario(exclusao.idUsuario).then(res => {
        globalLoading.hide();
       toast.success('Usuario Excluido com sucesso!',{
         position: toast.POSITION.TOP_RIGHT
       })
       setConsultaUsuarios(true);
      }).catch(err=> {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          navigate.push("/login")
        }
       toast.error(err.response.data.message,{
         position: toast.POSITION.TOP_RIGHT
       })
      })
     }

     const excluirUsuarioHandle = (prod)=> {
      setExclusao(prod);
      setShowMessage(true);
     }


  return (


    <React.Fragment>
       <div className="page-content">
       <Breadcrumbs title="Tables" breadcrumbItem="Consulta de Usuarios" />
        <Row>
          <Card>
            <CardBody>
            <Row>
            <Col lg={6}>
              <Label className="form-label">Email</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Email"
                      name="email"
                    />
              </Col>
            </Row>
            <br></br>
            <Row className="">
              <Col lg={9}></Col>
              <Col lg={3} className="d-flex flex-row-reverse">
                <Link to="cadastro-Usuarios" className="btn btn-primary waves-effect waves-light">
                  Novo <i className="bx bx-plus font-size-8 align-middle me-1"></i>
                </Link>
                &nbsp; &nbsp; &nbsp;
                <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light" onClick={() => setConsultaUsuarios(true)}>
                      Consultar <i className="bx bx-search font-size-8 align-middle me-1"></i>
                  </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Lista de Usuarios</CardTitle>
                    <table  className="table table-hover mb-0">
                      <thead className="table-light">
                        <tr>
                          <th style={{width:  '70%'}}>Email</th>
                          <th style={{width:  '18%'}}>Status</th>
                          <th style={{width:  '12%', textAlign:'center'}}>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        { rowItens.map(row => 
                            <tr key={row.idUsuario}>
                              <td style={{width: '70%'}}>{row.email}</td>
                              <td style={{width: '18%'}}>{row.status === 1?'ATIVO':'INATIVO'}</td>
                              <td style={{width: '12%', textAlign:'center'}}>
                                <button type="button" className="btn btn-light waves-effect waves-light" 
                                  onClick={() => toCadastroUsuario(row,true) }>
                                  <i className="bx bx-edit font-size-8 align-middle me-1"></i>
                                </button>
                                &nbsp;
                              <button type="button" className="btn btn-light waves-effect waves-light"
                                onClick={() => excluirUsuarioHandle(row) }>
                                  <i className="bx bx-trash font-size-8 align-middle me-1"></i>
                                </button>
                              </td>
                              {showMsg ? (<SweetAlert
                                  title="Deseja Realmente Excluir este Usuário?"
                                  warning
                                  showCancel
                                  confirmBtnBsStyle="Sim"
                                  cancelBtnBsStyle="Não"
                                  onConfirm={() => {
                                    excluirUsuario()
                                    setShowMessage(false);
                                  }}
                                  onCancel={() => {
                                    setShowMessage(false);
                                  }}>
                                </SweetAlert>):null}
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                    <Row style={{display:'flex'}}>
                      <Col lg={5} ></Col>
                      <Col lg={1} >
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={total}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                          />
                      </Col>
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
            </Row>
              
            </CardBody>
          </Card>
        </Row>
       </div>
    </React.Fragment>
  )
}

ListaUsuarios.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
}


export default ListaUsuarios

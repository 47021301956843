import { AvFeedback, AvInput } from "availity-reactstrap-validation";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Label } from "reactstrap";
import './AutoComplete.scss';
const AutoComplete = (props) => {
  const [active, setActive] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [item, setItem] = useState({});
  const [input, setInput] = useState('');
  const [label, setLabel] = useState('');
  const [name, setName] = useState('');
  const [required, setRequired] = useState(false);
  const [msgRequired, setMsgRequired] = useState('');

  useEffect(()=> {
    if(label == '') {
      setLabel(props.label);
      setName(props.name);
      setRequired(props.required);
      setMsgRequired(props.msgRequired);
    }
  });

  const onChange = e => {
    const { items} = props;
    const input = e.currentTarget.value;
    const newFilteredItems = items.filter(
      item =>
        item.toLowerCase().indexOf(input.toLowerCase()) > -1
    );
    setActive(0);
    setFiltered(newFilteredItems);
    setIsShow(true);
    setInput(e.currentTarget.value)
  };
const onClick = e => {
    setActive(0);
    setFiltered([]);
    setIsShow(false);
    setInput(e.currentTarget.innerText);
    setItem(e.currentTarget.value);
  };
  
const onKeyDown = e => {
    if (e.keyCode === 13) { // enter key
      setActive(0);
      setIsShow(false);
      setInput(filtered[active])
    }
    else if (e.keyCode === 38) { // up arrow
      return (active === 0) ? null : setActive(active - 1);
    }
    else if (e.keyCode === 40) { // down arrow
      return (active - 1 === filtered.length) ? null : setActive(active + 1);
    }
  };
const renderAutocomplete = () => {
    if (isShow && input) {
      if (filtered.length) {
        return (
          <ul className="autocomplete">
            {filtered.map((item, index) => {
              let className;
              if (index === active) {
                className = "active";
              }
              return (
                <li className={className} key={item} onClick={onClick}>
                  {item}
                </li>
              );
            })}
          </ul>
        );
      } else {
        return (
          <div className="no-autocomplete">
            <em>Nenhum produto encontrado</em>
          </div>
        );
      }
    }
    return null;
  }
return (
    <>
      <Label className="form-label">{label}</Label>
      <AvInput
        type="text"
        name ={'autoComplete'+name}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={input}
        required={required}
      />
      {required && (<AvFeedback>{msgRequired}</AvFeedback>)}
      {renderAutocomplete()}
    </>
  );
}
export default AutoComplete;
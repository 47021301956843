import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, CardBody, Card, Button, Form, FormFeedback, FormGroup, Input, Label, } from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import { AvForm, AvField, AvGroup, AvInput,AvFeedback } from 'availity-reactstrap-validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getExportador,editExportador,createExportador} from "../../../helpers/backend_helper"
import { globalLoading } from "react-global-loading";

const CadastroExportadores = props => {
  const history = useHistory();
  const [salvarExportador, setSalvarExportador] = useState(false);
  const [exportador, setExportador] = useState({});
  const [vizualizacao, setVizualizacao] = useState(false);
  const [idExportador, setIdExportador] = useState(null);
  
  useEffect(() => {
    if(salvarExportador) {
      globalLoading.show();
      if(!exportador.idExportador) {
        createExportador(exportador).then(res => {
          globalLoading.hide();
            setExportador({});
            toast.success("Exportador Salvo com Sucesso",{
              position: toast.POSITION.TOP_RIGHT
            }) 
            history.push('lista-exportadores')
        }).catch(err => {
          globalLoading.hide();
          if(err.response && err.response.status == 401) {
            localStorage.removeItem("token")
            localStorage.removeItem("authUser")
            history.push("/login")
          }
          toast.error(err.message,{
            position: toast.POSITION.TOP_RIGHT
          }) 
        });
    } else {
      editExportador(exportador).then(res => {
        globalLoading.hide();
        setExportador({});
        toast.success("Exportador editado com Sucesso",{
          position: toast.POSITION.TOP_RIGHT
        }) 
        history.push('lista-exportadores')
      }).catch(err => {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          history.push("/login")
        }
        toast.error(err.message,{
          position: toast.POSITION.TOP_RIGHT
        });
      })
    setSalvarExportador(false);
   }
  }
 },[salvarExportador])

  useEffect(() => {
    if(exportador && typeof idExportador == 'number' && !exportador.idExportador) {
      globalLoading.show();
    getExportador(idExportador)
    .then((res) => {
      globalLoading.hide();
      setExportador(res.data);
    }).catch(err => {
      globalLoading.hide();
      if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          history.push("/login")
      }
    });
  }
  },[idExportador])

  useEffect(() => {
    if(!idExportador && props.location && props.location.state && !exportador.idExportador) {
      setIdExportador(props.location.state.exportador.idExportador || {});
      setVizualizacao(props.location.state.vizualizacao);
    }
  })

  const handleSubmit = (event, values) => {
    if(!exportador.idExportador) {
      setExportador(values)
    } else {
      exportador.nome = values.nome;
      exportador.endereco = values.endereco;
      exportador.descricao = values.descricao;
    }
    setSalvarExportador(true);
  };

  return (
    <React.Fragment>
    <div className="page-content">
    <ToastContainer />
    <Breadcrumbs title="Tables" breadcrumbItem="Cadastro de Exportadores" />
     <Row>
       <Card>
         <CardBody>
         <AvForm onValidSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <AvGroup>
                <Label className="form-label" htmlFor="nome">Nome</Label>
                <AvInput 
                      name="nome"
                      required
                      type="text"
                      value= {exportador.nome ? exportador.nome : ''}
                      placeholder="Nome"
                      id="nome"
                      disabled ={vizualizacao}
                    />
               <AvFeedback>Informe o Nome</AvFeedback>
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
          <Col md={12}>
            <AvGroup>
                <Label className="form-label" htmlFor="endereco">Endereço</Label>
                <AvInput
                      name="endereco"
                      type="text"
                      required
                      value={exportador.endereco ? exportador.endereco : ''}
                      placeholder="Endereço"
                      id="endereco"
                      disabled ={vizualizacao}
                    />
               <AvFeedback>Informe o Endereço</AvFeedback>
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
          <Col md={12}>
            <AvGroup>
                <Label className="form-label" htmlFor="descricao">Descrição</Label>
                <AvInput
                      name="descricao"
                      type="textarea"
                      rows="5"
                      value={exportador.descricao ? exportador.descricao : ''}
                      placeholder="Descrição"
                      id="descricao"
                      disabled ={vizualizacao}
                    />
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
          <Col lg={9}></Col>
                <Col lg={3} className="d-flex flex-row-reverse">
                {!vizualizacao && (<Button
                        color="primary" type="submit"
                        className="btn btn-primary waves-effect waves-light">
                        salvar Exportador <i className="bx bx-check font-size-8 align-middle me-1"></i>
                  </Button>
                  )}
                  &nbsp;
                  <Link to="lista-Exportadores" className="btn btn-primary waves-effect waves-light">
                    voltar <i className="bx bx-arrow-back font-size-8 align-middle me-1"></i>
                  </Link>
                </Col>
            </Row>
          </AvForm>
         </CardBody>
       </Card>
     </Row>
    </div>
 </React.Fragment>
  )
}

CadastroExportadores.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
}


export default CadastroExportadores

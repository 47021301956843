import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, CardBody, Card, CardTitle,Progress,Label,Button, Table, Input } from "reactstrap"
import "../../../assets/scss/datatables.scss"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "../../../components/Common/Pagination"
import {toast } from 'react-toastify';
import {getFornecedores,deleteFornecedor, getOrcamentos, getClientesAll, deleteOrcamento, downloadXLSOrcamento, sendExcel} from "../../../helpers/backend_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import { globalLoading } from "react-global-loading"
let PageSize = 5;

const ListaOrcamentos = props => {
  const inputFile = useRef(null) 
  const [excel, setExcel] = useState('')
  const navigate = useHistory();
  const [infoModal, setInfoModal] = useState(false)
  const [infoExcelModal, setInfoExcelModal] = useState(false)
  const [rowItens, setRowItens] = useState([]);
  const [total, setTotal] = useState(0);
  const [consultaOrcamentos, setConsultaOrcamentos] = useState(true);
  const [currentPage, setCurrentPage] = useState(null);
  const [exclusao, setExclusao] = useState({});
  const [filtrosAtuais, setFiltrosAtuais] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [consultaClientes, setConsultaClientes] = useState(true);
  const [showMsg, setShowMessage] = useState(false);

  useEffect(() => {
  	if(!(typeof excel == 'string')){
      if(excel.type === '.csv' || excel.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || excel.type === 'application/vnd.ms-excel') {
        globalLoading.show();
        const fileReader = new FileReader();
        fileReader.readAsDataURL(excel)
        fileReader.onload = () => {
          sendArquivoExcel(fileReader.result)
        }
        fileReader.onerror = (error) => {
          globalLoading.hide();
          console.log(error);
        }
      } else {
        toast.error('So pode ser enviado arquivos no formato Excel',{
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  },[excel]);


  function sendArquivoExcel(base64) {
      sendExcel({"file":base64}).then((res) => {
        globalLoading.hide();
        setInfoExcelModal(true);
      });
  }

  const onExcelButtonClick = () => {
    inputFile.current.click();
  }

  const currentTableData = useMemo(async () => {
    if(currentPage) {
      consultarOrcamentos()
    }
  }, [currentPage]);

    useEffect(() => {
      if(consultaOrcamentos) {
        setCurrentPage(currentPage && isFiltrosNaoPreenchidosOuAlterados() ? currentPage : 1);
        consultarOrcamentos();
        setConsultaOrcamentos(false);
      }
    },[consultaOrcamentos])

    function consultarOrcamentos() {
      globalLoading.show();
      let dataInicial = document.getElementsByName("dataInicial")[0] && document.getElementsByName("dataInicial")[0].value;
      let dataFinal = document.getElementsByName("dataFinal")[0] && document.getElementsByName("dataFinal")[0].value;
      let idCliente = document.getElementsByName("idCliente")[0] && document.getElementsByName("idCliente")[0].value;
      if(!verificarPeriodo(dataInicial,dataFinal)) {
        globalLoading.hide();
        toast.error('Preencha o periodo antes de enviar as datas!',{
          position: toast.POSITION.TOP_RIGHT
        })
        return;
      }
      setFiltrosAtuais({"dataInicial":dataInicial, "dataFinal":dataFinal, "idCliente":idCliente});

      getOrcamentos(
        {"pagina":currentPage == null || currentPage === 0? 0: currentPage -1  ,
        "tamanho":PageSize, "dataInicial":dataInicial, "dataFinal":dataFinal, "idCliente":idCliente})
          .then((res) => {
            globalLoading.hide();
            let orcamentos = [];
            for (let index = 0; index < res.data.length; index++) {
              let rowItem = {}
              rowItem["idOrcamento"] = res.data[index].idOrcamento;
              rowItem["precoTotal"] = res.data[index].precoTotal;
              rowItem["pesoTotalBruto"] = res.data[index].pesoTotalBruto;
              rowItem["cliente"] =  res.data[index].cliente;
              rowItem["statusExp"] =  res.data[index].statusExp;
              rowItem["dataCriacao"] =  new Date(res.data[index].created_at);
              orcamentos.push(rowItem);
            }
            setTotal(res.total);
            setRowItens(orcamentos);
          }).catch(err => {
            globalLoading.hide();
            if(err.response && err.response.status == 401) {
              localStorage.removeItem("token")
              localStorage.removeItem("authUser")
              navigate.push("/login")
            }
          })
    }

    const isFiltrosNaoPreenchidosOuAlterados = () => {
      return !isFiltrosPreenchidos() || isFiltrosIguais();
    }
    
    const isFiltrosPreenchidos = () => {
      let dataInicial = document.getElementsByName("dataInicial")[0] && document.getElementsByName("dataInicial")[0].value;
      let dataFinal = document.getElementsByName("dataFinal")[0] && document.getElementsByName("dataFinal")[0].value;
      let idCliente = document.getElementsByName("idCliente")[0] && document.getElementsByName("idCliente")[0].value;
      return dataInicial || dataFinal || idCliente;
    }

    const isFiltrosIguais = () => {
      let dataInicial = document.getElementsByName("dataInicial")[0] && document.getElementsByName("dataInicial")[0].value;
      let dataFinal = document.getElementsByName("dataFinal")[0] && document.getElementsByName("dataFinal")[0].value;
      let idCliente = document.getElementsByName("idCliente")[0] && document.getElementsByName("idCliente")[0].value;
      return filtrosAtuais.dataInicial === dataInicial 
          && filtrosAtuais.dataFinal === dataFinal 
          && filtrosAtuais.idCliente === idCliente
    }

    const toCadastroOrcamento = (orcamento, isVizualizacao)=> {;
      navigate.push({
        pathname: 'cadastro-orcamentos',
        state: {orcamento:orcamento,vizualizacao:isVizualizacao} 
        });
    }

    const excluirOrcamento = ()=> {
    globalLoading.show();
     deleteOrcamento(exclusao.idOrcamento).then(res => {
      globalLoading.hide();
      toast.success('Orçamento Excluido com sucesso!',{
        position: toast.POSITION.TOP_RIGHT
      })
      setConsultaClientes(true);
     }).catch(err=> {
      globalLoading.hide();
      if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          navigate.push("/login")
      }
      toast.error(err.response.data.message,{
        position: toast.POSITION.TOP_RIGHT
      })
     })
    }

    function verificarPeriodo(input1, input2) {
      if(input1 && !input2) {
        return false;
      } else if(!input1 && input2) {
        return false
      } else {
        return true;
      }
    }

    useEffect(() => {
      if(consultaClientes) {
        globalLoading.show();
        getClientesAll()
        .then((res) => {
          globalLoading.hide();
          setClientes(res.clientes);
          setConsultaClientes(false)
        }).catch(err => {
          globalLoading.hide();
          if(err.response && err.response.status == 401) {
            localStorage.removeItem("token")
            localStorage.removeItem("authUser")
            navigate.push("/login")
          }
        });
      }
    },[consultaClientes])

    const excluirOrcamentoHandle = (orc)=> {
      setExclusao(orc);
      setShowMessage(true);
     }

     const downloadXLS = (orc)=> {
      globalLoading.show();
      downloadXLSOrcamento(orc.idOrcamento).then(res => {
        globalLoading.hide();
        setInfoModal(true);
       }).catch(err=> {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          navigate.push("/login")
        }
        toast.error(err.response.data.message,{
          position: toast.POSITION.TOP_RIGHT
        })
       })
     }

     const limparFormulario = () => {
      document.getElementsByName("dataInicial")[0].value = null;
      document.getElementsByName("dataFinal")[0].value = null;
      document.getElementsByName("idCliente")[0].value = null;

     }

  return (
    <React.Fragment>
       <div className="page-content">
       <Breadcrumbs title="Tables" breadcrumbItem="Consulta de Orçamentos" />
        <Row>
          <Card>
            <CardBody>
            <Row>
            <Col lg={4}>
              <Label className="form-label">Data Inicial</Label>
              <input
                      className="form-control"
                      type="date"
                      name="dataInicial"
                    />
              </Col>
              <Col lg={4}>
              <Label className="form-label">Data Final</Label>
              <input
                      className="form-control"
                      type="date"
                      name="dataFinal"
                    />
              </Col>
              <Col lg={4}>
              <Label className="form-label">Clientes</Label>
                <select  className="form-control" name="idCliente" label="Cliente">
                    <option></option>
                    {clientes.map(cliente => <option value={cliente.idCliente}>{cliente.nome}</option>)}
                </select>
              </Col>
            </Row>
            <br></br>
            <Row className="">
              <Col lg={8}></Col>
              <Col lg={4} className="d-flex flex-row-reverse">
                <Link to="cadastro-orcamentos" className="btn btn-primary waves-effect waves-light">
                  Novo <i className="bx bx-plus font-size-8 align-middle me-1"></i>
                </Link>
                &nbsp; &nbsp; &nbsp;
                <Button
                    color="primary"
                    className="btn btn-primary waves-effect waves-light" onClick={() => setConsultaOrcamentos(true)}>
                    Consultar <i className="bx bx-search font-size-8 align-middle me-1"></i>
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button
                    color="primary"
                    className="btn btn-primary waves-effect waves-light" onClick={() => limparFormulario()}>
                    Limpar
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button  color="primary" className="btn btn-primary waves-effect waves-light"
                                  onClick={ onExcelButtonClick}>
                    Importar Orçamento &nbsp;
                    <i className="bx bx-upload font-size-8 align-middle me-1"></i>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Lista de Orçamentos</CardTitle>
                    <table  className="table table-hover mb-0">
                      <thead className="table-light">
                        <tr>
                          <th style={{width:  '5%', textAlign:'center'}}>ID</th>
                          <th style={{width:  '35%'}}>Cliente</th>
                          <th style={{width:  '10%', textAlign:'center'}}>Data de Criação</th>
                          <th style={{width:  '15%', textAlign:'center'}}>Preço Total</th>
                          <th style={{width:  '15%', textAlign:'center'}}>Peso Total Bruto</th>
                          <th style={{textAlign:'center'}}>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        { rowItens.map(row => 
                            <tr key={row.idOrcamento}>
                              <td style={{width: '5%', textAlign:'center'}}>{row.idOrcamento}</td>
                              <td style={{width: '35%'}}>{row.cliente.nome}</td>
                              <td style={{width:  '10%', textAlign:'center'}}>{row.dataCriacao.toLocaleDateString('pt-BR')}</td>
                              <td style={{width:  '15%', textAlign:'center'}}>{
                                'R$ ' + Intl.NumberFormat('pt-BR').format(row.precoTotal)}</td>
                              <td style={{width:  '15%', textAlign:'center'}}>{row.pesoTotalBruto}</td>
                              <td  style={{textAlign:'center'}}>
                                <button type="button" className="btn btn-light waves-effect waves-light" 
                                 onClick={() => toCadastroOrcamento(row,false) }>
                                  <i className="bx bx-edit font-size-8 align-middle me-1"></i>
                                </button>
                                &nbsp;
                                <button type="button" className="btn btn-light waves-effect waves-light"
                                onClick={() => toCadastroOrcamento(row,true) }>
                                  <i className="bx bx-search font-size-8 align-middle me-1"></i>
                                </button>
                                &nbsp;
                                <button type="button" className="btn btn-light waves-effect waves-light"
                                onClick={() => excluirOrcamentoHandle(row) }>
                                  <i className="bx bx-trash font-size-8 align-middle me-1"></i>
                                </button>
                                &nbsp;
                                {row.statusExp === 0 ? <button type="button" className="btn btn-light waves-effect waves-light"
                                  onClick={() => downloadXLS(row) }>
                                  <i className="bx bx-download font-size-8 align-middle me-1"></i>
                                </button>: null}
                              </td>
                              {showMsg ? (<SweetAlert
                                  title="Deseja Realmente Excluir este Orçamento?"
                                  warning
                                  showCancel
                                  confirmBtnBsStyle="Sim"
                                  cancelBtnBsStyle="Não"
                                  onConfirm={() => {
                                    excluirOrcamento()
                                    setShowMessage(false);
                                  }}
                                  onCancel={() => {
                                    setShowMessage(false);
                                  }}>
                                </SweetAlert>):null}
                                {infoModal ? (<SweetAlert
                                  title="O Orçamento está sendo processado, quando concluído será enviado por email."
                                  info
                                  confirmBtnBsStyle="Confirmar"
                                  onConfirm={() => {
                                    setConsultaOrcamentos(true);
                                    setInfoModal(false);
                                  }}>
                                </SweetAlert>):null}
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                    <Row style={{display:'flex'}}>
                      <Col lg={5} ></Col>
                      <Col lg={1} >
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={total}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                          />
                      </Col>
                      <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                              ref={inputFile} name="excel" id="excel" onChange={(e) => setExcel(e.target.files[0])} style={{visibility: 'hidden'}}/>
                    </Row>
                    {infoExcelModal ? 
                    (<SweetAlert
                            title="O orçamento importado foi enviado. Em breve, você receberá um e-mail informando se ele foi incluído ou não com sucesso."
                              info
                            confirmBtnBsStyle="Confirmar"
                              onConfirm={() => {
                                inputFile.current.value = null;
                                setInfoExcelModal(false);
                              }}>
                    </SweetAlert>):null
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
              
            </CardBody>
          </Card>
        </Row>
       </div>
    </React.Fragment>
  )
}

ListaOrcamentos.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
}


export default ListaOrcamentos

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, CardBody, Card, Button, Form, FormFeedback, FormGroup, Input, Label, } from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import { AvForm, AvField, AvGroup, AvInput,AvFeedback } from 'availity-reactstrap-validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getFornecedor,editFornecedor,createFornecedor, getExportadoresAll} from "../../../helpers/backend_helper"
import { globalLoading } from "react-global-loading";

const CadastroFornecedores = props => {
  const history = useHistory();
  const [salvarFornecedor, setSalvarFornecedor] = useState(false);
  const [fornecedor, setFornecedor] = useState({});
  const [exportador, setExportador] = useState({});
  const [consultarExportadores, setConsultarExpotadores] = useState(true);
  const [vizualizacao, setVizualizacao] = useState(false);
  const [exportadores, setExportadores] = useState([]);
  const [idFornecedor, setIdFornecedor] = useState(null);
  
  useEffect(() => {
    if(salvarFornecedor) {
      globalLoading.show();
      if(!fornecedor.idFornecedor) {
        createFornecedor(fornecedor).then(res => {
            globalLoading.hide();
            setFornecedor({});
            toast.success("Fornecedor Salvo com Sucesso",{
              position: toast.POSITION.TOP_RIGHT
            }) 
            history.push('lista-fornecedores')
        }).catch(err => {
          globalLoading.hide();
          if(err.response && err.response.status == 401) {
            localStorage.removeItem("token")
            localStorage.removeItem("authUser")
            history.push("/login")
          }
          toast.error(err.message,{
            position: toast.POSITION.TOP_RIGHT
          })
          fornecedor.exportador = exportador;
          setSalvarFornecedor(false);
        });
    } else {
      let fornEdit ={...fornecedor}
      fornEdit.idExportador = typeof exportador === 'object' ? exportador.idExportador:exportador;
      delete fornEdit['exportador'];
      editFornecedor(fornEdit).then(res => {
        globalLoading.hide();
        setFornecedor({});
        toast.success("Fornecedor editado com Sucesso",{
          position: toast.POSITION.TOP_RIGHT
        }) 
        history.push('lista-fornecedores')
      }).catch(err => {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          history.push("/login")
        }
        toast.error(err.message,{
          position: toast.POSITION.TOP_RIGHT
        });
        fornecedor.exportador = exportador;
        setSalvarFornecedor(false);
        fornecedor.exportador = exportador;
      })
    setSalvarFornecedor(false);
   }
  }
 },[salvarFornecedor])

  useEffect(() => {
    if(typeof idFornecedor == 'number' && !fornecedor.idFornecedor) {
      globalLoading.show();
      getFornecedor(idFornecedor)
        .then((res) => {
          globalLoading.hide();
          setFornecedor(res.retorno);
          setExportador(res.retorno.exportador);
    }).catch(err=> {
      globalLoading.hide();
      if(err.response && err.response.status == 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("authUser")
        history.push("/login")
      }
     toast.error(err.response.data.message,{
       position: toast.POSITION.TOP_RIGHT
     })
    });
  }
  },[idFornecedor])

  useEffect(() => {
    if(!idFornecedor && props.location && props.location.state && !fornecedor.idFornecedor) {
      setIdFornecedor(props.location.state.fornecedor.idFornecedor || {});
      setVizualizacao(props.location.state.vizualizacao);
    }
  })

  useEffect(() => {
    if(consultarExportadores) {
      globalLoading.show();
      getExportadoresAll()
      .then((res) => {
        globalLoading.hide();
        setExportadores(res.exportadores);
        setConsultarExpotadores(false)
      }).catch(err=> {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          history.push("/login")
        }
       toast.error(err.response.data.message,{
         position: toast.POSITION.TOP_RIGHT
       })
      });
    }
  },[consultarExportadores])

  const handleSubmit = (event, values) => {
    if(!fornecedor.idFornecedor) {
      setFornecedor(values)
    } else {
      fornecedor.nome = values.nome;
      fornecedor.descricao = values.descricao;
    }
    setSalvarFornecedor(true);
  };

  const handleChangeExport = (event) => {
    setExportador(event.target.value);
  };

  return (
    <React.Fragment>
    <div className="page-content">
    
    <Breadcrumbs title="Tables" breadcrumbItem="Cadastro de Fornecedores" />
     <Row>
       <Card>
         <CardBody>
         <AvForm onValidSubmit={handleSubmit} va>
          <Row>
            <Col md={6}>
              <AvGroup>
                <Label className="form-label" htmlFor="nome">Nome</Label>
                <AvInput
                      name="nome"
                      required
                      value= {fornecedor.nome ? fornecedor.nome : ''}
                      type="text"
                      placeholder="Nome"
                      id="nome"
                      disabled ={vizualizacao}
                    />
               <AvFeedback>Informe o Nome</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={6}>
            <AvGroup>
              <AvField type="select" name="idExportador" label="Exportador"
              value={fornecedor.exportador? fornecedor.exportador.idExportador:null}
              disabled ={vizualizacao} onChange={handleChangeExport}
              validate={{required: { value: true, errorMessage: 'Selecione o Exportador'}}}>
                  <option value={null}></option>
                  {exportadores.map(exportador => <option value={exportador.idExportador}>{exportador.nome}</option>)}
              </AvField>
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
          <Col md={12}>
            <AvGroup>
                <Label className="form-label" htmlFor="descricao">Descrição</Label>
                <AvInput
                      name="descricao"
                      required
                      type="textarea"
                      rows="5"
                      value= {fornecedor.descricao ? fornecedor.descricao : ''}
                      placeholder="Descrição"
                      id="descricao"
                      disabled ={vizualizacao}
                    />
               <AvFeedback>Informe a Descrição</AvFeedback>
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
          <Col lg={9}></Col>
                <Col lg={3} className="d-flex flex-row-reverse">
                {!vizualizacao && (<Button
                        color="primary" type="submit"
                        className="btn btn-primary waves-effect waves-light">
                        salvar Fornecedor <i className="bx bx-check font-size-8 align-middle me-1"></i>
                  </Button>
                  )}
                  &nbsp;
                  <Link to="lista-fornecedores" className="btn btn-primary waves-effect waves-light">
                    voltar <i className="bx bx-arrow-back font-size-8 align-middle me-1"></i>
                  </Link>
                </Col>
            </Row>
          </AvForm>
         </CardBody>
       </Card>
     </Row>
    </div>
 </React.Fragment>
  )
}

CadastroFornecedores.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
}


export default CadastroFornecedores
